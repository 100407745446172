module.exports = {
  siteMetadata: {
    title: "Mute Tube",
    description: "Noise and vibration control acoustics across London.",
    siteUrl: "https://mute.tube",
  },
  plugins: [
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: `Xxjd7ViOqIrlPySP4hY0GAtt`,
        homeSlug: "home",
        version: "published",
        localAssets: true,
        resolveRelations: [
          //Add Relations here
          //Example: page.menu
          "moreCaseStudies.caseStudies",
        ],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `MuteTube`,
        short_name: `ld.gatsby-storyblok-start`,
        start_url: `/`,
        background_color: `#f7f0eb`,
        theme_color: `#a2466c`,
        display: `standalone`,
        icon: "src/assets/images/favicon.png",
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: `${__dirname}/src/assets/svg/`,
        },
      },
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: true,
        fileName: true,
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [`poppins\:100,200,300,400,500,600,700,800`],
        display: "swap",
      },
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        headers: {
          "/*": [`X-Frame-Options: ALLOW`],
        },
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "G-PN7RFFPRWZ",
        includeInDevelopment: false,
        defaultDataLayer: { platform: "MUTE" },
        routeChangeEventName: "MUTE_ROUTE_CHANGE",
        enableWebVitalsTracking: true,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-catch-links`,
    `gatsby-plugin-sitemap`,
    `gatsby-plugin-sass`,
  ],
};
